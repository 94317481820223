import styled from 'styled-components';

const SalesFormButton = styled.button`
  background-color: #00c2ff;
  font-family: Poppins;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  border: none;
  padding: 1.2rem 2.4rem;
  border-radius: 0.5rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.4s ease;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export default SalesFormButton;
