import PropTypes from 'prop-types';
import PolicyLetterSection from './PolicyLetterSection';

const PolicyCoverageMotor = ({ coverLevel, excessLevel }) => {
  return (
    <>
      <h2>Försäkringsinnehåll</h2>
      <PolicyLetterSection>
        <table>
          <thead>
            <tr>
              <th>Moment</th>
              <th>Självrisk</th>
              <th>Viktig information</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Trafik</td>
              <td>1 500 kr</td>
              <td></td>
            </tr>
            {(coverLevel === 'Halv' || coverLevel === 'Hel') && (
              <>
                <tr>
                  <td>Stöld</td>
                  <td>2 000 kr</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Maskin</td>
                  <td>
                    5 000 kr
                    <br />8 000 kr
                  </td>
                  <td>
                    Upp till 6 000 mil eller 5 år
                    <br />
                    När bilen gått mer än 6 000 mil eller mer än 5 år
                    <br />
                    Skydd för maskinskada upphör då bilen blir 8 år eller om den
                    körts mer än 10 000 mil
                  </td>
                </tr>
                <tr>
                  <td>Brand</td>
                  <td>2 000 kr</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Glas</td>
                  <td>
                    0 kr
                    <br />2 000 kr
                  </td>
                  <td>
                    Laga ett stenskott. Maximalt 3 gånger per försäkringsår
                    <br />
                    Vid byte av ruta
                  </td>
                </tr>
                <tr>
                  <td>Räddning</td>
                  <td>1 750 kr</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Rättsskydd</td>
                  <td>20 %</td>
                  <td>Lägst 2 000 kr</td>
                </tr>
                <tr>
                  <td>Kristerapi</td>
                  <td>0 kr</td>
                  <td>Max 10 tillfällen under en 12-månadersperiod</td>
                </tr>
              </>
            )}
            {coverLevel === 'Hel' && (
              <tr>
                <td>Vagnskada</td>
                <td>{Number(excessLevel).toLocaleString('sv-SE')} kr</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </PolicyLetterSection>
      <br />
      <br />
    </>
  );
};

PolicyCoverageMotor.propTypes = {
  coverLevel: PropTypes.string.isRequired,
  excessLevel: PropTypes.string,
};

export default PolicyCoverageMotor;
