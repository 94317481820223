import { createRoot } from 'react-dom/client';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import App from './App';

// const matomoInstance = createInstance({
//   urlBase: 'https://eirforsakring.matomo.cloud',
//   siteId: process.env.REACT_APP_MATOMO_SITE_ID || 9999,
// });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
// root.render(
//   <MatomoProvider value={matomoInstance}>
//     <App />
//   </MatomoProvider>
// );
