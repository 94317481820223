import PropTypes from 'prop-types';
import PolicyLetterSection from './PolicyLetterSection';

const PaymentSchedule = ({ paymentSchedule }) => {
  return (
    <>
      <h2>Betalningsplan</h2>
      <PolicyLetterSection>
        <table>
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Betald</th>
              <th>Dag</th>
              <th>Belopp</th>
            </tr>
          </thead>
          <tbody>
            {paymentSchedule.map(payment => (
              <tr key={payment.index}>
                <td>{payment.index}</td>
                <td>{payment.collected ? 'Ja' : '-'}</td>
                <td>{payment.collectionDay}</td>
                <td>
                  {payment.amount.toLocaleString('sv-SE', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{' '}
                  kr
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PolicyLetterSection>
    </>
  );
};

PaymentSchedule.propTypes = {
  paymentSchedule: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      collected: PropTypes.bool.isRequired,
      collectionDay: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default PaymentSchedule;
