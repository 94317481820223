import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatDrivingDistance } from '@eir/utils';

import PolicyLetterSection from './PolicyLetterSection';

const PolicyOverviewMotor = ({
  answers,
  coverLevel,
  end,
  name,
  policyNumber,
  policyPremium,
  start,
}) => {
  const localeStringOptions = [
    'sv-SE',
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
  ];

  return (
    <>
      <PolicyLetterSection>
        <ItemContainer>
          <label>Försäkringstagare</label>
          {name}
        </ItemContainer>
        <ItemContainer>
          <label>Försäkringsperiod</label>
          {start} - {end}
        </ItemContainer>
        <ItemContainer>
          <label>Registreringsnummer</label>
          {answers.registrationNumber}
        </ItemContainer>
        <ItemContainer>
          <label>Försäkringsnummer</label>
          {policyNumber}
        </ItemContainer>
        <ItemContainer>
          <label>Omfattning</label>
          {coverLevel}
        </ItemContainer>
        <ItemContainer>
          <label>Årlig körsträcka</label>
          {formatDrivingDistance(answers.mileage)} mil
        </ItemContainer>
        <ItemContainer>
          <label>Boendeform</label>
          {answers.dwelling}
        </ItemContainer>
        <ItemContainer>
          <label>Förare under 25</label>
          {answers.youngDriver}
        </ItemContainer>
        {(coverLevel === 'Halv' || coverLevel === 'Hel') && (
          <>
            <ItemContainer>
              <label>Hyrbil</label>
              {answers?.optHiredCar === 'true' || answers?.optHiredCar === 'Ja'
                ? 'Ja'
                : 'Nej'}
            </ItemContainer>
            <ItemContainer>
              <label>Djurkollision</label>
              {answers?.optAnimalCollision === 'true' ||
              answers?.optAnimalCollision === 'Ja' ||
              answers?.optRoadSide === 'true' ||
              answers?.optRoadSide === 'Ja'
                ? 'Ja'
                : 'Nej'}
            </ItemContainer>
          </>
        )}
        <ItemContainer>
          <label>Premie</label>
          {(
            policyPremium.totalPremiumExcludingTax + policyPremium.totalTax
          ).toLocaleString(...localeStringOptions)}{' '}
          kr
        </ItemContainer>
        <ItemContainer>
          <label>Trafikpremieskatt</label>
          {policyPremium.totalTax.toLocaleString(...localeStringOptions)} kr
        </ItemContainer>
      </PolicyLetterSection>
      <Disclaimer>
        Detta försäkringsbrev beskriver din försäkring. Det är viktigt att du
        kontrollerar att de uppgifter vi har om dig och ditt fordon är korrekta
        eftersom de ligger till grund för din premie och hur mycket du betalar
        för din försäkring.
        <br />
        <br />
        Felaktiga uppgifter kan påverka ersättningen vid skada.
      </Disclaimer>
      <br />
      <br />
    </>
  );
};

PolicyOverviewMotor.propTypes = {
  answers: PropTypes.shape({
    dwelling: PropTypes.string.isRequired,
    mileage: PropTypes.string.isRequired,
    optAnimalCollision: PropTypes.string,
    optHiredCar: PropTypes.string,
    registrationNumber: PropTypes.string.isRequired,
    youngDriver: PropTypes.string.isRequired,
  }).isRequired,
  coverLevel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  policyPremium: PropTypes.shape({
    sumPremium: PropTypes.number.isRequired,
    sumTax: PropTypes.number.isRequired,
  }).isRequired,
};

export default PolicyOverviewMotor;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 700;
  margin-bottom: 0.625rem;
  padding: 0 0 0.625rem;
  border-bottom: 0.0625rem solid #e5e5e8;

  &:last-child {
    border: 0;
  }

  @media (min-width: 48rem) {
    flex-direction: row;
  }

  label {
    min-width: 11.25rem;
    font-weight: 400;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.8125rem;
  color: #7b7d8c;
`;
