import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { createQuoteService } from '@eir/services';
import { formatDate } from '@eir/utils';

import Header from '../../components/Header';
import Documents from './components/Documents';
import Price from './components/Price';
import useForm from '../../hooks/useForm';
import NoQuote from './components/NoQuote';
import SalesFormContents from './components/SalesFormContents';

const OnlineSalesView = () => {
  const { product } = useParams();

  const productTranslations = {
    hem: 'lin-contents',
  };

  const buttonTexts = {
    default: 'Beräkna ditt pris',
    calculated: 'Ditt pris är beräknat',
    calculating: 'Vi beräknar ditt pris',
    calculateNew: 'Beräkna nytt pris',
  };

  const [quoteResponse, setQuoteResponse] = useState(null);
  const [hasQuoteError, setHasQuoteError] = useState(false);
  const [isCalculatingPrice, setIsCalculatingPrice] = useState(false);
  const [buttonText, setButtonText] = useState(buttonTexts.default);

  const priceRef = useRef(null);
  const errorRef = useRef(null);

  const header = {
    hem: 'Teckna hemförsäkring',
  };

  const initialValues = {
    hem: {
      area: '70',
      coverLevel: 'Base',
      email: '',
      excess: '1500',
      optCondoAddOn: 'true',
      optExtra: 'true',
      optTravel: 'true',
      paymentMethod: 'Fixed 1',
      product: 'lin-contents',
      productVersion: 'latest',
      reference: 'osa',
      securitySolution: 'Nej',
      socialSecurity: '',
      startDate: new Date(),
      rentedOut: 'Nej',
      residents: '1',
      sumInsured: '300000',
      telephoneNumber: '',
    },
  };

  const validations = {
    hem: {
      email: {
        required: {
          value: true,
          message: 'Fyll i din e-postadress',
        },
        pattern: {
          value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          message: 'Ange en giltig e-postadress',
        },
      },
      telephoneNumber: {
        required: {
          value: true,
          message: 'Fyll i ditt telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
        pattern: {
          value: '[0-9]{12}$',
          message: 'Fyll i ditt personnummer i formatet ÅÅÅÅMMDDnnnn',
        },
      },
    },
  };

  const { handleSubmit, handleChange, data, errors } = useForm({
    initialValues: initialValues[product],
    validations: validations[product],
    onSubmit: e => {
      setIsCalculatingPrice(true);
      setButtonText(buttonTexts.calculating);

      const body = { ...data };

      body.startDate = formatDate(body.startDate);
      body.socialSecurity = body.socialSecurity.replace('-', '');

      const createQuote = async () => {
        try {
          setQuoteResponse(await createQuoteService('LinAPI', body));
          setHasQuoteError(false);
          setButtonText(buttonTexts.calculated);

          setTimeout(() => {
            setButtonText(buttonTexts.calculateNew);
          }, 1500);
        } catch (error) {
          setHasQuoteError(true);
          setButtonText(buttonTexts.default);

          if (errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } finally {
          setIsCalculatingPrice(false);
        }
      };

      createQuote();
    },
  });

  useEffect(() => {
    if (quoteResponse && priceRef.current) {
      priceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [quoteResponse, priceRef]);

  useEffect(() => {
    if (hasQuoteError && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hasQuoteError, errorRef]);

  return (
    <PageWrapper>
      <Header>
        <H1>{header[product]}</H1>
      </Header>
      <ContentWrapper>
        <main>
          {product === 'hem' && (
            <SalesFormContents
              data={data}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              buttonText={buttonText}
              isCalculatingPrice={isCalculatingPrice}
            />
          )}
        </main>
        <Aside>
          {hasQuoteError ? (
            <NoQuote ref={errorRef} />
          ) : (
            quoteResponse && <Price ref={priceRef} quote={quoteResponse} />
          )}
          <Documents productName={productTranslations[product]} />
        </Aside>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default OnlineSalesView;

const PageWrapper = styled.div`
  background-color: #dfecf4;
  margin: auto;
  padding-bottom: 2rem;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100%, 37.5rem);
  gap: 1rem;
  justify-content: center;

  @media (min-width: 54rem) {
    grid-template-columns: minmax(auto, 42rem) 18.25rem;
    padding: 0 2rem;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 54rem) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

const H1 = styled.h1`
  text-align: center;

  span {
    white-space: nowrap;
  }
`;
