import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { getOsaPolicyService, useApiRequest } from '@eir/services';

import Header from '../../components/Header';
import PaymentSchedule from './components/PaymentSchedule';
import PolicyCoverageMotor from './components/PolicyCoverageMotor';
import PolicyOverviewMotor from './components/PolicyOverviewMotor';
import PolicyOverviewContents from './components/PolicyOverviewContents';
import PolicyCoverageContents from './components/PolicyCoverageContents';

const PolicyLetterView = () => {
  const match = useRouteMatch('/p/:policyId');

  const { data } = useApiRequest(
    getOsaPolicyService('LinAPI', match.params.policyId)
  );

  const getHeader = () => {
    switch (data.answers.product) {
      case 'lin-contents':
        return 'Försäkringsbrev Hem';
      case 'lin-motor':
        return 'Försäkringsbrev Bilförsäkring';
      default:
        return '';
    }
  };

  return (
    data && (
      <PolicyLetterContainer>
        <Header>
          <h1>{getHeader()}</h1>
        </Header>
        <main>
          {data.answers.product === 'lin-contents' && (
            <>
              <PolicyOverviewContents
                answers={data.answers}
                start={data.start}
                end={data.end}
                name={data.name}
                policyNumber={data.policyNumber}
                policyPremium={data.policyPremium}
              />
              <PolicyCoverageContents answers={data.answers} />
            </>
          )}
          {data.answers.product === 'lin-motor' && (
            <>
              <PolicyOverviewMotor
                answers={data.answers}
                start={data.start}
                end={data.end}
                name={data.name}
                policyNumber={data.policyNumber}
                coverLevel={data.coverLevel}
                policyPremium={data.policyPremium}
              />
              <PolicyCoverageMotor
                coverLevel={data.coverLevel}
                excessLevel={data.answers.excessLevel}
              />
            </>
          )}
          <PaymentSchedule paymentSchedule={data.paymentSchedule.schedule} />
          <br />
          <a
            href='https://linforsakring.se/villkor/'
            target='_blank'
            rel='noreferrer'
          >
            Fullständiga villkor
          </a>
        </main>
      </PolicyLetterContainer>
    )
  );
};

export default PolicyLetterView;

// const Header = styled.header`
//   display: flex;
//   flex-direction: column;
//   margin: auto;
//   margin-bottom: 2.5rem;
//   align-items: center;
// `;

const Logo = styled.img`
  width: auto;
  height: 6rem;
  margin-bottom: 2rem;
`;

const PolicyLetterContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  margin: 1.875rem auto;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }
`;
