import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Helmet } from 'react-helmet';
import { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

import { configureAmplify } from '@eir/aws-authorization';
import { SalesFormView } from '@eir/sales-form';
import { WorkshopsOnMap } from '@eir/workshops-on-map';
import { AttachView, PaymentView, PolicyLetterView } from './views';
import GlobalStyle from './styles/globalStyle';
import './styles/fonts.css';
import OnlineSalesView from './views/onlineSales/OnlineSalesView';

registerLocale('sv', sv);

configureAmplify(
  {
    endpointName: 'LinAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  // const { trackPageView } = useMatomo();

  const onResize = () => {
    window.parent.postMessage(
      {
        type: 'resize',
        height: document.body.scrollHeight,
      },
      '*'
    );
  };

  // useEffect(() => {
  //   trackPageView();
  // }, []);

  useEffect(() => {
    onResize();
    new ResizeObserver(_ => onResize()).observe(document.body);
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          href='https://d2l0gvltru79ov.cloudfront.net/css/lin/partner-settings.css'
        />
      </Helmet>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route
            exact
            path='/reattach/:paymentProvider/:ledgerId'
            component={AttachView}
          />
          <Route exact path='/p/:policyId' component={PolicyLetterView} />
          <Route
            exact
            path='/pay/:paymentProvider/:quoteId'
            component={PaymentView}
          />
          <Route exact path='/teckna' component={SalesFormView} />
          <Route exact path='/teckna/:product' component={OnlineSalesView} />
          <Route exact path='/verkstader' component={WorkshopsOnMap} />
        </Switch>
      </Router>
      {/* <Footer>
        <a
          href='https://www.linforsakring.se/integritetspolicy'
          target='_blank'
          rel='noreferrer'
        >
          Integritetspolicy
        </a>
        <AddressBlock>
          <span>Försäkringsgivare:</span>
          <span>Eir Försäkring AB, org. 559166-0617</span>
          <span>Kungsgatan 37 111 56 Stockholm, Sweden</span>
        </AddressBlock>
      </Footer> */}
    </>
  );
};

export default App;
