import styled from 'styled-components';

import Section from '../../../components/Section';
import pdfIcon from '../../../images/pdf-icon.svg';

const Documents = ({ productName }) => {
  return (
    <Section>
      <header>
        <h3>Dokument</h3>
      </header>
      <main>
        <DocumentsList>
          <li>
            <Icon src={pdfIcon} alt='PDF dokument' />
            {productName === 'lin-contents' && (
              <a
                href={process.env.REACT_APP_DOCUMENT_FORKOP_BOENDE}
                target='_blank'
                rel='noreferrer'
              >
                Förköpsinformation
              </a>
            )}
          </li>
          <li>
            <Icon src={pdfIcon} alt='PDF dokument' />
            {productName === 'lin-contents' && (
              <a
                href={process.env.REACT_APP_DOCUMENT_IPID_BOENDE}
                target='_blank'
                rel='noreferrer'
              >
                Produktblad
              </a>
            )}
          </li>
          <li>
            <Icon src={pdfIcon} alt='PDF dokument' />
            {productName === 'lin-contents' && (
              <a
                href={process.env.REACT_APP_DOCUMENT_VILLKOR_BOENDE}
                target='_blank'
                rel='noreferrer'
              >
                Försäkringsvillkor
              </a>
            )}
          </li>
        </DocumentsList>
      </main>
    </Section>
  );
};

export default Documents;

const DocumentsList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  li {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
`;

const Icon = styled.img`
  width: auto;
  height: 1.2rem;
`;
