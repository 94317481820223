import { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { purchaseService } from '@eir/services';

import Header from '../../components/Header';

const PaymentView = () => {
  const match = useRouteMatch('/pay/:paymentProvider/:quoteId');
  const [error, setError] = useState(false);

  const redirectToPaymentProvider = async () => {
    try {
      const data = await purchaseService(
        'LinAPI',
        match.params.paymentProvider,
        match.params.quoteId
      );
      window.location.replace(data.redirectUrl);
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  redirectToPaymentProvider();

  return (
    <PaymentContainer>
      <Header>
        <h1>{error ? 'Något gick fel' : 'Vänligen vänta...'}</h1>
      </Header>
      {error && (
        <main>
          Det gick inte att genomföra betalningen. Vänligen kontakta vår
          kundeservice på telefon <Phone>08 - 520 276 58</Phone> eller e-post
          info@linforsakring.se så hjälper vi dig.
        </main>
      )}
    </PaymentContainer>
  );
};

export default PaymentView;

const Logo = styled.img`
  width: auto;
  height: 6rem;
  margin-bottom: 2rem;
`;

const PaymentContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  margin: 1.875rem auto;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }
`;

const Phone = styled.span`
  white-space: nowrap;
`;
