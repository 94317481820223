import { memo } from 'react';
import styled from 'styled-components';

const RangeSlider = ({ name, value, minValue, maxValue, onChange, unit }) => {
  return (
    <>
      <RangeSliderWrapper>
        <RangeInput
          name={name}
          type='range'
          value={value === '' ? minValue : value}
          min={minValue}
          max={maxValue}
          onChange={onChange}
        />
      </RangeSliderWrapper>
      <RangeValueInputWrapper>
        <RangeValueInput name={name} value={value} onChange={onChange} />
        {unit && <span>{unit}</span>}
      </RangeValueInputWrapper>
    </>
  );
};

export default memo(RangeSlider);

const RangeSliderWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 0.5rem;
`;

const RangeInput = styled.input`
  appearance: none;
  width: 100%;
  height: 0.25rem;
  border: 0;
  padding: 0;
  background-color: #ebebeb;
  border-radius: 0.25rem;
  background-image: linear-gradient(
    rgba(0, 194, 255, 0.25),
    rgba(0, 194, 255, 0.8)
  );
  background-size: ${props =>
      `${
        ((Number(props.value) - Number(props.min)) * 100) /
        (Number(props.max) - Number(props.min))
      }%`}
    100%;
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: #00c2ff;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;

    @media (min-width: 54rem) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

const RangeValueInputWrapper = styled.div`
  background-color: #f5f5f5;
  border: 0;
  transition: none;
  transition: background-color 0.2s ease;
  align-self: flex-start;
  border-radius: 0.25rem;

  &:focus-within {
    background-color: #fff;
    border: 0.125rem solid #00c2ff;

    span {
      padding-right: 0.5rem;
    }
  }

  span {
    font-size: 1.4rem;
    padding-right: 0.375rem;
  }
`;

const Input = styled.input`
  font-size: 1.4rem;
  line-height: 1;
  border-radius: 0.25rem;
  background-color: transparent;
  border: none;
  padding: 0.75rem 0.5rem;
  transition: none;
  transition: background-color 0.2s ease;

  &:focus {
    background-color: transparent;
    border: 0;
    outline: none;
    padding: 0.625rem 0.375rem;
  }
`;

const RangeValueInput = styled(Input)`
  width: 4rem;
`;
